<template>
  <div :style="`height: ${bodyHeight - floorHeight}px`" class="category">
    <div class="search" id="search" @click="toSearchPage">
      <div class="search-content">
        <van-icon class="sc-icon" name="search" />
        <input placeholder="特仑苏" />
      </div>
    </div>
    <ul
      :class="[
        userAgent == 'PC' ? 'demo' : '',
        scrollTop == 0 ? '' : 'cHide',
        'topCategory',
      ]"
      id="topCategory"
    >
      <li
        :class="topCur == index ? 'cur' : ''"
        :key="index"
        @click="topCategoryClick(index)"
        class="tc-li"
        v-for="(item, index) in topCategory"
      >
        <img :class="[topCur == index ? 'border' : '']" :src="imgurl + item.img" alt />
        <span>{{ item.name }}</span>
      </li>
    </ul>
    <ul
      :class="[
        userAgent == 'PC' ? 'demo' : '',
        scrollTop > 0 ? '' : 'cHide',
        'topCategory topCategory-flxed',
      ]"
      id="topCategoryFlxed"
    >
      <li
        :class="topCur == index ? 'cur' : ''"
        :key="index"
        @click="topCategoryClick(index)"
        class="tc-li flxed-li"
        v-for="(item, index) in topCategory"
      >
        <span>{{ item.name }}</span>
      </li>
    </ul>
    <div :style="`height: calc(100% - ${conHeight}px)`" class="conCategory">
      <ul :class="[userAgent == 'PC' ? 'demo' : '', 'sideCategory']" ref="sideCategory">
        <li
          :key="index"
          class="sc-li"
          :class="topCur == index ? 'cur' : ''"
          v-for="(item, index) in sideCategory"
        >
          <span @click="topCategoryClick(index)">{{ item.name }}</span>
          <ul class="sc-chlidren" v-show="topCur == index">
            <li
              :key="indexChilderen"
              :class="[itemChildren.active, 'sc-child-li']"
              v-for="(itemChildren, indexChilderen) in item.children"
              @click="handelGoodList(itemChildren, topCur,indexChilderen)"
            >
              <!-- :class="topCur == index && indexChilderen == 0 ? 'cur2' : ''" -->

              <!-- @click="getGoodsList(itemChildren.id)" -->
              {{ itemChildren.name }}
            </li>
          </ul>
        </li>
      </ul>
      <div
        style="padding-top: 55px"
        @touchstart="touchstart"
        @touchmove="touchmove"
        @touchend="touchend"
        @scroll="handleScroll"
        :class="[
          userAgent == 'PC' ? 'demo' : '',
          is_overflow_hidden ? 'overflow-hidden' : '',
          'goods',
        ]"
        ref="goods"
      >
        <van-card
          v-for="(item, index) in goodList"
          :key="index"
          @click="toGoodsDetail(item.id)"
          :desc="item.introduction"
          num="2"
          :price="'₱' + item.price + '.00'"
          :title="item.name"
        >
          <div slot="tag">
            <img v-show="tagimg(item)" style="width:80px" src="@/assets/ic_torrmow.png" alt />
          </div>
          <template #thumb>
            <img :src="imgurl + item.image" alt />
            <img
              v-show="sellOutimg(item)"
              style="position: absolute;z-index: 10;left:0"
              src="@/assets/shoujin_bg.png"
              alt
            />
          </template>
          <template #num>
            <van-icon class="add-icon" name="add" />
          </template>
        </van-card>
        <div style="height:150px"></div>
        <!-- <div style="height:50px"></div> -->
      </div>
    </div>
    <!-- ce -->
    <!-- <div class="loading" id="loading">
      <span style="color: #969799; font-size: 16px" v-if="showFinish">加载完成</span>
      <p style="color:red" v-else>加载中...</p>
      <van-loading size="24px" v-else>加载中...</van-loading>
    </div>
    <van-icon class="pullDown" name="replay" v-show="shwoPull" />-->
  </div>
</template>

<script>
import {
  imgApp,
  getListOne,
  getList,
  getGoodsList,
  getAllItem
} from "@/service/api/index.js";
import { Toast } from "vant";

export default {
  name: "category",
  data() {
    return {
      userAgent: "",
      is_overflow_hidden: false,
      moveY: 0,
      goodList: [],
      imgurl: "",
      topCategory: [],
      sideCategory: [],
      topCur: 0, // 顶部分类选中后的索引
      sideCur: 0, // 侧部分类选中后的索引
      bodyHeight: 750, // 屏幕高度
      floorHeight: 57, // 底部导航栏高度
      scrollTop: 0, // 滚动高度
      canScroll: 0, // 可滚动总高度
      startY: 0, // 触摸滑动开始y轴位置
      shwoPull: false, // 下拉更新弹框
      loading: false, // 上拉更新弹框
      showFinish: false, // 上拉更新完成提示
      refHeight: {
        // 各组件高度
        search: 0,
        topCategory: 0,
        topCategoryFlxed: 0,
        loading: 0
      },

      firstName: 1,
      lastName: 1,
      childrenIndex: 0
    };
  },
  computed: {
    conHeight() {
      // 设置中间conCategory区域高度
      const h = this.refHeight;
      return this.scrollTop == 0
        ? h.search + h.topCategory
        : this.loading == true
        ? h.search + h.topCategoryFlxed + h.loading
        : h.search + h.topCategoryFlxed;
    }
  },
  activated() {
    // console.log(123)
    // console.log(this.$route.meta.isBack)
    // console.log(this.scrollTop)
    if (!this.$route.meta.isBack) {
      if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
        this.userAgent = "IOS";
      } else if (/(Android)/i.test(navigator.userAgent)) {
        this.userAgent = "这是Android";
      } else {
        this.userAgent = "PC";
      }
      this.userAgent = "";
      this.is_overflow_hidden = false;
      this.moveY = 0;
      this.goodList = [];
      this.imgurl = "";
      this.topCategory = [];
      this.sideCategory = [];
      this.topCur = 0; // 顶部分类选中后的索引
      this.sideCur = 0; // 侧部分类选中后的索引
      this.bodyHeight = 750; // 屏幕高度
      this.floorHeight = 57; // 底部导航栏高度
      this.scrollTop = 0; // 滚动高度
      this.canScroll = 0; // 可滚动总高度
      this.startY = 0; // 触摸滑动开始y轴位置
      this.shwoPull = false; // 下拉更新弹框
      this.loading = false; // 上拉更新弹框
      this.showFinish = false; // 上拉更新完成提示
      this.refHeight = {
        // 各组件高度
        search: 0,
        topCategory: 0,
        topCategoryFlxed: 0,
        loading: 0
      };
      this.firstName = 1;
      this.lastName = 1;
      this.childrenIndex = 0;
      this.getElHeight();
      this.getGoodsHeight();
      this.imgurl = imgApp;
      //获取一级分类
      getListOne()
        .then(async res => {
          this.topCategory = res.data;
          this.sideCategory = res.data;
          //判断是否由首页跳转过来
          if (this.$route.params.id) {
            let index = res.data.findIndex(_ => _.id == this.$route.params.id);
            // 获取首页跳转过来对应的下标
            if (index != -1) {
              this.topCur = index; //将下标对应分类选择样式
              // 获取首页点击过来的二级分类
              await this.getList(this.$route.params.id);
              for (const item of this.sideCategory) {
                for (const el of item.children || []) {
                  this.$set(el, "active", "");
                }
              }
              let childrenIndex = 0;
              for (let i in this.sideCategory[this.topCur].children) {
                if (
                  this.sideCategory[this.topCur].children[i].id ==
                  this.$route.params.childrenId
                ) {
                  childrenIndex = i;
                }
              }
              this.childrenIndex = childrenIndex;
              this.$set(
                this.sideCategory[this.topCur].children[childrenIndex],
                "active",
                "cur2"
              );
              this.getGoodsList(this.$route.params.childrenId);
              return;
            }
          } else {
            // 获取二级分类,默认为分类第一个
            await this.getList(res.data[0].id);
            for (const item of this.sideCategory) {
              for (const el of item.children || []) {
                this.$set(el, "active", "");
              }
            }
            this.$set(
              this.sideCategory[this.topCur].children[0],
              "active",
              "cur2"
            );
          }
        })
        .catch(err => {});
    } else {
      //返回页面不变
      this.setIsback();
      // let childrenIndex = localStorage.getItem("childrenIndex");
      // this.getGoodsList(
      //   this.sideCategory[this.topCur].children[childrenIndex].id
      // );
      // for (const item of this.sideCategory) {
      //   for (const el of item.children || []) {
      //     this.$set(el, "active", "");
      //   }
      // }
      // this.$set(
      //   this.sideCategory[this.topCur].children[childrenIndex],
      //   "active",
      //   "cur2"
      // );
      // this.$refs.goods.scrollTop = localStorage.getItem("scrollTop");
    }
    this.$route.meta.isBack = false;
  },
  // created() {},
  // mounted() {},
  methods: {
    handelGoodList(itemChildren, i, index) {
      this.$refs.goods.scrollTop = 0;
      this.getGoodsList(itemChildren.id);
      for (const item of this.sideCategory) {
        for (const el of item.children || []) {
          this.$set(el, "active", "");
        }
      }
      this.childrenIndex = index;
      this.$set(itemChildren, "active", "cur2");
    },
    async getList(id) {
      const res = await getList({ id: id, pageIndex: 1, pageSize: 999 });
      if (res.status === 200) {
        let index = this.sideCategory.findIndex(item => item.id == id);
        if (index != -1) {
          this.sideCategory[index].children = res.data;
        }
        //获取二级对应的商品列表,默认二级分类第一个
        if (this.sideCategory[index].children.length > 0) {
          if (!this.$route.params.id) {
            this.getGoodsList(this.sideCategory[index].children[0].id);
          }
          this.$forceUpdate();
        }
      }
    },
    async getGoodsList(id) {
      const res = await getAllItem({ id: id, pageIndex: 1, pageSize: 999 });
      if (res.status == 200) {
        this.goodList = res.data.spuVos;
        this.$forceUpdate();
      }
    },
    async topCategoryClick(index) {
      this.topCur = index;
      this.$refs.goods.scrollTop = 0;
      if (
        this.sideCategory[index].children &&
        this.sideCategory[index].children.length > 0
      ) {
        await this.getGoodsList(this.sideCategory[index].children[0].id);
        for (const item of this.sideCategory) {
          for (const el of item.children || []) {
            this.$set(el, "active", "");
          }
        }
        if (this.sideCategory[this.topCur].children.length > 0) {
          this.$set(
            this.sideCategory[this.topCur].children[0],
            "active",
            "cur2"
          );
          this.$forceUpdate();
        }
      } else {
        await this.getList(this.sideCategory[index].id);
        for (const item of this.sideCategory) {
          for (const el of item.children || []) {
            this.$set(el, "active", "");
          }
        }
        if (this.sideCategory[index].children.length > 0) {
          this.$set(this.sideCategory[index].children[0], "active", "cur2");
          this.$forceUpdate();
        }
      }
    },
    async setIsback() {
      let childrenIndex = localStorage.getItem("childrenIndex");
      // this.getGoodsList(
      //   this.sideCategory[this.topCur].children[childrenIndex].id
      // );
      const res = await getAllItem({
        id: this.sideCategory[this.topCur].children[childrenIndex].id,
        pageIndex: 1,
        pageSize: 999
      });
      if (res.status == 200) {
        this.goodList = res.data.spuVos;
        this.$forceUpdate();
      }
      for (const item of this.sideCategory) {
        for (const el of item.children || []) {
          this.$set(el, "active", "");
        }
      }
      this.$set(
        this.sideCategory[this.topCur].children[childrenIndex],
        "active",
        "cur2"
      );
      console.log(res);
      console.log("执行了", localStorage.getItem("scrollTop"));
      this.$nextTick(() => {
        this.$refs.goods.scrollTop = localStorage.getItem("scrollTop");
      });
    },
    toGoodsDetail(id) {
      console.log(id);
      console.log(this.childrenIndex);
      localStorage.setItem("childrenIndex", this.childrenIndex);
      this.$router.push({ path: "/goodsDetail", query: { id: id } });
    },
    getElHeight() {
      // 获取各组件高度
      this.bodyHeight = document.body.offsetHeight;
      this.floorHeight = document.getElementById("AppTabbar").clientHeight;
      this.refHeight.search = document.getElementById("search").clientHeight;
      this.refHeight.topCategory = document.getElementById(
        "topCategory"
      ).clientHeight;
      this.refHeight.topCategoryFlxed = document.getElementById(
        "topCategoryFlxed"
      ).clientHeight;
      //ce
      // this.refHeight.loading = document.getElementById("loading").clientHeight;
      this.$refs.goods.addEventListener("scroll", this.scrollEv);
    },
    async handleScroll() {
      if (this.userAgent !== "PC") {
        return;
      }
      if (
        this.$refs.goods.scrollHeight -
          this.$refs.goods.scrollTop -
          this.$refs.goods.clientHeight <=
        1
      ) {
        console.log(456);
        this.$refs.goods.scrollTop = 0;
        const list = this.sideCategory[this.topCur].children || [];
        const i = list.findIndex(e => e.active);
        const index = i + 1;
        if (list[index]) {
          this.is_overflow_hidden = true;
          await this.getGoodsList(list[index].id);
          this.is_overflow_hidden = false;
          for (const item of this.sideCategory) {
            for (const el of item.children || []) {
              this.$set(el, "active", "");
            }
          }
          this.childrenIndex = index;
          this.$set(list[index], "active", "cur2");
        } else {
          this.topCur++;
          const index = this.topCur;
          if (this.sideCategory[index]) {
            this.is_overflow_hidden = true;
            await this.getList(this.sideCategory[index].id);
            this.is_overflow_hidden = false;
            for (const item of this.sideCategory) {
              for (const el of item.children || []) {
                this.$set(el, "active", "");
              }
            }
            const list_ = this.sideCategory[index].children || [];
            if (list_.length > 0) {
              const i = list_.findIndex(e => e.active);
              const i_ = i + 1;
              this.childrenIndex = i_;
              this.$set(list_[i_], "active", "cur2");
            }
          } else {
            this.topCur--;
          }
        }
      }
    },
    touchstart(e) {
      console.log("touchstart",e.touches[0].clientY)
      this.startY = e.touches[0].clientY;
    },
    async touchend(e) {
      console.log("touchend",)
      // let moveY = e.touches[0].clientY;
      // this.moveY = moveY;
      if (this.startY - this.moveY <= -100 && this.scrollTop == 0) {
        this.shwoPull = true;
        getListOne()
          .then(async res => {
            this.shwoPull = false;
            this.topCategory = res.data;
            this.sideCategory = res.data;
            //判断是否由首页跳转过来
            if (this.$route.params.id) {
              let index = res.data.findIndex(
                _ => _.id == this.$route.params.id
              );
              // 获取首页跳转过来对应的下标
              if (index != -1) {
                this.topCur = index; //将下标对应分类选择样式
                // 获取首页点击过来的二级分类
                await this.getList(this.$route.params.id);
                for (const item of this.sideCategory) {
                  for (const el of item.children || []) {
                    this.$set(el, "active", "");
                  }
                }
                this.$set(
                  this.sideCategory[this.topCur].children[0],
                  "active",
                  "cur2"
                );
                return;
              }
            }
            // 获取二级分类,默认为分类第一个
            this.topCur = 0;
            await this.getList(res.data[0].id);
            for (const item of this.sideCategory) {
              for (const el of item.children || []) {
                this.$set(el, "active", "");
              }
            }
            this.$set(
              this.sideCategory[this.topCur].children[0],
              "active",
              "cur2"
            );
          })
          .catch(err => {});
        // setTimeout(() => {
        //   this.shwoPull = false;
        // }, 2000);
      }
      // console.log({
      //   "scrollHeight": this.$refs.goods.scrollHeight,
      //   "scrollTop": this.$refs.goods.scrollTop,
      //   "clientHeight": this.$refs.goods.clientHeight,
      // },this.$refs.goods.scrollHeight -
      //     this.$refs.goods.scrollTop -
      //     this.$refs.goods.clientHeight,this.startY - this.moveY)
      if (
          this.$refs.goods.scrollHeight -
          this.$refs.goods.scrollTop -
          this.$refs.goods.clientHeight <=
          20 &&
        this.startY - this.moveY >= 100
      ) {
        console.log(123);
        this.$refs.goods.scrollTop = 0;
        const list = this.sideCategory[this.topCur].children || [];
        const i = list.findIndex(e => e.active);
        const index = i + 1;
        if (list[index]) {
          this.is_overflow_hidden = true;
          await this.getGoodsList(list[index].id);
          this.is_overflow_hidden = false;
          for (const item of this.sideCategory) {
            for (const el of item.children || []) {
              this.$set(el, "active", "");
            }
          }
          this.childrenIndex = index;
          this.$set(list[index], "active", "cur2");
        } else {
          this.topCur++;
          const index = this.topCur;
          if (this.sideCategory[index]) {
            this.is_overflow_hidden = true;
            await this.getList(this.sideCategory[index].id);
            this.is_overflow_hidden = false;
            for (const item of this.sideCategory) {
              for (const el of item.children || []) {
                this.$set(el, "active", "");
              }
            }
            const list_ = this.sideCategory[index].children || [];
            if (list_.length > 0) {
              const i = list_.findIndex(e => e.active);
              const i_ = i + 1;
              this.childrenIndex = i_;
              this.$set(list_[i_], "active", "cur2");
            }
          } else {
            this.topCur--;
          }
        }
      }
    },
    async touchmove(e) {
      console.log("touchmove",e.touches[0].clientY)
      let moveY = e.touches[0].clientY;
      this.moveY = moveY;
      if (this.startY - moveY >= 100 && this.scrollTop >= this.canScroll) {
        this.loading = true;
        this.showFinish = false;
        setTimeout(() => {
          this.showFinish = true;
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        }, 2000);
      }
    },
    getGoodsHeight() {
      // 获取可滚动高度  有误差需-2
      let goods = this.$refs.goods;
      this.canScroll = goods.scrollHeight - goods.clientHeight - 2;
    },
    scrollEv() {
      this.scrollTop = Math.ceil(this.$refs.goods.scrollTop);
      // console.log("我先", Math.ceil(this.$refs.goods.scrollTop));
      if (this.scrollTop != 0) {
        localStorage.setItem(
          "scrollTop",
          Math.ceil(this.$refs.goods.scrollTop)
        );
      }
      // console.log(this.scrollTop)
    },
    toSearchPage() {
      this.$router.push("/searchAddress");
    },
    tagimg(item) {
      if (
        (item.stockingTime == 1 || item.stockingTime == 2) &&
        item.totalStock <= 0
      ) {
        return true;
      } else {
        return false;
      }
    },
    sellOutimg(item) {
      if (item.totalStock <= 0 && item.stockingTime >= 30) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style lang="less" scoped>
/* 设置垂直滚动条的宽度和水平滚动条的高度 */
.demo::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* 设置滚动条的滑轨 */
.demo::-webkit-scrollbar-track {
  background-color: #ddd;
}

/* 滑块 */
.demo::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

/* 滑轨两头的监听按钮 */
.demo::-webkit-scrollbar-button {
  background-color: #888;
  display: none;
}

/* 横向滚动条和纵向滚动条相交处尖角 */
.demo::-webkit-scrollbar-corner {
  /*background-color: black;*/
}
.border {
  border: 1px solid #00b05a;
  border-radius: 50%;
  box-sizing: border-box;
}
.overflow-hidden {
  overflow: hidden;
}
.cHide {
  position: absolute;
  left: 99999px;
  display: none !important;
}
.category {
  background-color: #fff;
  height: 100%;
  overflow: hidden;
  .search {
    box-sizing: border-box;
    height: 44px;
    padding: 6px 15px;
    .search-content {
      width: 100%;
      height: 30px;
      line-height: 30px;
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 1px solid #e6e6e6;
      border-radius: 20px;
      .sc-icon {
        margin-left: 14px;
        font-size: 20px;
        line-height: 35px;
        color: #cacacc;
      }
      input {
        display: block;
        width: 100%;
        height: 20px;
        border: none;
        padding-left: 5px;
        color: #b5b5b5;
        font-size: 12px;
        margin-right: 15px;
      }
    }
  }
}
.topCategory {
  width: 100%;
  height: 90px;
  display: flex;
  justify-content: flex-start;
  overflow-x: scroll;
  .tc-li {
    width: 75px;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    &.cur {
      span {
        color: #00b05a;
      }
    }
    img {
      width: 54px;
      height: 54px;
      border-radius: 50%;
      overflow: hidden;
    }
    span {
      display: inline-block;
      width: 75px;
      text-align: center;
      line-height: 24px;
      font-size: 12px;
    }
  }

  &.topCategory-flxed {
    height: 40px;
    .flxed-li {
      position: relative;
      height: 40px;
      &.cur {
        span {
          &::before {
            content: "";
            position: absolute;
            display: block;
            left: 27.5px;
            bottom: 5px;
            width: 20px;
            height: 4px;
            border-radius: 2px;
            background-color: #00b05a;
          }
        }
      }
      span {
        font-size: 14px;
        line-height: 40px;
      }
    }
  }
}
.conCategory {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: calc(100% - 134px);
  overflow: hidden;
}
.sideCategory {
  width: 75px;
  float: left;
  font-size: 14px;
  height: 100%;
  overflow-x: scroll;
  background-color: #f4f5f6;
  .sc-li {
    width: 75px;
    color: #6d7278;
    &.cur {
      background-color: #fff;
      span {
        color: #000;
        font-weight: bold;
        // &::before {
        //   background-color: #00b05a;
        // }
      }
    }
    span {
      position: relative;
      display: inline-block;
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      &::before {
        content: "";
        position: absolute;
        display: block;
        left: 0;
        top: 5px;
        width: 4px;
        height: 30px;
        border-radius: 2px;
        background-color: transparent;
      }
    }
    .sc-chlidren {
      // padding-left: 5px;

      .sc-child-li {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        position: relative;
        &.cur {
          color: #00b05a;
        }
        &.cur2::before {
          background-color: #00b05a !important;
        }

        &::before {
          content: "";
          position: absolute;
          display: block;
          left: 0;
          top: 5px;
          width: 4px;
          height: 30px;
          border-radius: 2px;
          background-color: transparent;
        }
      }
    }
  }
}
.goods {
  width: calc(100% - 75px);
  height: 100%;
  overflow-x: scroll;
}
@keyframes changeright {
  0% {
    -webkit-transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
.pullDown {
  position: fixed;
  float: left;
  top: 60px;
  left: 167.5px;
  border-radius: 50px;
  background-color: #fff;
  color: #00b05a;
  font-size: 35px;
  z-index: 999;
  animation: changeright 1.4s linear infinite;
}
.loading {
  // display: none;
  width: 100%;
  height: 60px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.add-icon {
  color: #00b05a;
  font-size: 24px;
}

/deep/ .van-card {
  bottom: 49px;
}

/deep/.van-card__price-currency {
  color: #df3436;
  visibility: hidden;
}
/deep/.van-card__price-integer,
.van-card__price-decimal {
  color: #df3436;
}
/deep/.van-card__title {
  font-weight: 900;
}
/deep/ .van-card__thumb {
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
