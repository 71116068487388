var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"category",style:(("height: " + (_vm.bodyHeight - _vm.floorHeight) + "px"))},[_c('div',{staticClass:"search",attrs:{"id":"search"},on:{"click":_vm.toSearchPage}},[_c('div',{staticClass:"search-content"},[_c('van-icon',{staticClass:"sc-icon",attrs:{"name":"search"}}),_c('input',{attrs:{"placeholder":"特仑苏"}})],1)]),_c('ul',{class:[
      _vm.userAgent == 'PC' ? 'demo' : '',
      _vm.scrollTop == 0 ? '' : 'cHide',
      'topCategory' ],attrs:{"id":"topCategory"}},_vm._l((_vm.topCategory),function(item,index){return _c('li',{key:index,staticClass:"tc-li",class:_vm.topCur == index ? 'cur' : '',on:{"click":function($event){return _vm.topCategoryClick(index)}}},[_c('img',{class:[_vm.topCur == index ? 'border' : ''],attrs:{"src":_vm.imgurl + item.img,"alt":""}}),_c('span',[_vm._v(_vm._s(item.name))])])}),0),_c('ul',{class:[
      _vm.userAgent == 'PC' ? 'demo' : '',
      _vm.scrollTop > 0 ? '' : 'cHide',
      'topCategory topCategory-flxed' ],attrs:{"id":"topCategoryFlxed"}},_vm._l((_vm.topCategory),function(item,index){return _c('li',{key:index,staticClass:"tc-li flxed-li",class:_vm.topCur == index ? 'cur' : '',on:{"click":function($event){return _vm.topCategoryClick(index)}}},[_c('span',[_vm._v(_vm._s(item.name))])])}),0),_c('div',{staticClass:"conCategory",style:(("height: calc(100% - " + _vm.conHeight + "px)"))},[_c('ul',{ref:"sideCategory",class:[_vm.userAgent == 'PC' ? 'demo' : '', 'sideCategory']},_vm._l((_vm.sideCategory),function(item,index){return _c('li',{key:index,staticClass:"sc-li",class:_vm.topCur == index ? 'cur' : ''},[_c('span',{on:{"click":function($event){return _vm.topCategoryClick(index)}}},[_vm._v(_vm._s(item.name))]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.topCur == index),expression:"topCur == index"}],staticClass:"sc-chlidren"},_vm._l((item.children),function(itemChildren,indexChilderen){return _c('li',{key:indexChilderen,class:[itemChildren.active, 'sc-child-li'],on:{"click":function($event){return _vm.handelGoodList(itemChildren, _vm.topCur,indexChilderen)}}},[_vm._v(" "+_vm._s(itemChildren.name)+" ")])}),0)])}),0),_c('div',{ref:"goods",class:[
        _vm.userAgent == 'PC' ? 'demo' : '',
        _vm.is_overflow_hidden ? 'overflow-hidden' : '',
        'goods' ],staticStyle:{"padding-top":"55px"},on:{"touchstart":_vm.touchstart,"touchmove":_vm.touchmove,"touchend":_vm.touchend,"scroll":_vm.handleScroll}},[_vm._l((_vm.goodList),function(item,index){return _c('van-card',{key:index,attrs:{"desc":item.introduction,"num":"2","price":'₱' + item.price + '.00',"title":item.name},on:{"click":function($event){return _vm.toGoodsDetail(item.id)}},scopedSlots:_vm._u([{key:"thumb",fn:function(){return [_c('img',{attrs:{"src":_vm.imgurl + item.image,"alt":""}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.sellOutimg(item)),expression:"sellOutimg(item)"}],staticStyle:{"position":"absolute","z-index":"10","left":"0"},attrs:{"src":require("@/assets/shoujin_bg.png"),"alt":""}})]},proxy:true},{key:"num",fn:function(){return [_c('van-icon',{staticClass:"add-icon",attrs:{"name":"add"}})]},proxy:true}],null,true)},[_c('div',{attrs:{"slot":"tag"},slot:"tag"},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.tagimg(item)),expression:"tagimg(item)"}],staticStyle:{"width":"80px"},attrs:{"src":require("@/assets/ic_torrmow.png"),"alt":""}})])])}),_c('div',{staticStyle:{"height":"150px"}})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }